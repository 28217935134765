import Cookies from "js-cookie";
const tokenKey = "LULONG_TOKEN"; //设置一个独一无二的key
const timeKey = "LL-timestamp";
// 获取token
export function getToken() {
  return Cookies.get(tokenKey);
}
// 存储token
export function setToken(token) {
  return Cookies.set(tokenKey, token);
}
// 删除token
export function removeToken() {
  // console.log(tokenKey);
  return Cookies.remove(tokenKey);
}

// 当前的时间戳
export function setTimeStamp() {
  return Cookies.set(timeKey, Date.now());
}
// 读取时间戳
export function getTimeStamp() {
  return Cookies.get(timeKey);
}
export default {
  getToken,
  setToken,
  removeToken,
  setTimeStamp,
  getTimeStamp,
};
