import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

// 静态路由
import login from './staticMoudle/login'
import notFound from './staticMoudle/notFound'
import personal from './staticMoudle/personal'
import Gzhlogin from "./staticMoudle/Gzhlogin";
export const staticModules = [
  login,
  notFound,
  personal,
  Gzhlogin
]

const createRouter = () => new VueRouter({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    { path: '/', redirect: '/home'},
    ...staticModules,
  ]

})

  
//添加以下代码
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router
