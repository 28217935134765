import Cookies from "js-cookie";
const state = {
  isCollapse: Cookies.get('LULONG_MENU') === 'true'

}
const mutations = {
  TOGGLEMENU(state) {
    state.isCollapse = !state.isCollapse,
    Cookies.set('LULONG_MENU',state.isCollapse)
  }

}
export default {
  namespaced: true,
  state,
  mutations
};