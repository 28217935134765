import { staticModules } from '@/router'
import { tranListToTreeData } from '@/utils/tools'
import Layout from '@/layout'
const state = {
  // 任何一个账号都有静态路由
  routes:staticModules
}
const mutations = {
  setRoutes(state, newRoutes) {
    console.log([...staticModules, ...newRoutes]);
    state.routes = [...staticModules, ...newRoutes] //在静态路由的基础上去加
  }
}
const actions = {
 async setRoutes(context,menus) {
    const getRoutesList = (data, arr) => {
      data.forEach((datas, index) => {
        arr.push({
          path: datas.path,
          name: datas.name,
          component: datas.component === 'Layout' ? Layout : (resolve) => require([`@/views/${datas.component}`], resolve),
          redirect:datas.redirect,
          meta: {
            title: datas.title,
            icon: datas.icon,
            orderNum: datas.orderNum
          },
          // 子路由
          children: []
        })
        if (datas.children) {
          const childArr = getRoutesList(datas.children, [])
          arr[index].children = childArr
        }
      })
      return arr
    }
    const list = tranListToTreeData(menus,'0')
    const routes = await getRoutesList(list, [])
    console.log(routes,"routes");
    
    context.commit('setRoutes', routes)
    return routes
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}