import request from "../utils/request";
import qs from "qs";
// 获取用户资料
export const getUserInfo = () => {
  return request({
    method: "get",
    url: "/llUser/selectUserInfo",
  });
};

// 修改密码
export const changePwd = (data) => {
  return request({
    method: "post",
    url: "/llUser/updateByPassword",
    data,
  });
};
// 修改手机号
export const changePhone = (data) => {
  return request({
    method: "get",
    url: "/llUser/updatePhone?" + qs.stringify(data),
  });
};

// 获取通知列表
export const getAppealContent = (data) => {
  return request({
    method: "get",
    url: `/message/Query?recipientId=${data}`,
  });
};

// 删除通知
export const deleteNotice = (data) => {
  return request({
    method: "delete",
    url: "/message/delete",
    data,
  });
};
// 删除全部通知
export const deleteAllNotice = (data) => {
  return request({
    method: "delete",
    url: "/message/deleteAll",
    data,
  });
};
