<template>
  <div class="title-content">
    <div class="layout-title">
      <div style="margin-left: 20px;">
        <i class="el-icon-menu menu" style="float:left;cursor: pointer;" @click="toggleMenu"></i>
        <h1>卢龙县项目全生命周期服务平台</h1>
        <el-badge :value="noticeNum" :max="99" class="noticeIcon">
          <!-- <el-button size="small">评论</el-button> -->
          <i class="el-icon-chat-dot-square" @click="showNotice" style="cursor: pointer;"></i>
        </el-badge>
      </div>
    </div>
    <!-- <div class="tc"></div> -->
    <div class="title">
      <h1 class="title-contain" v-if="$route.meta.title">
        <i class="el-icon-s-tools"></i>
        {{ $route.meta.title }}
      </h1>
    </div>
    <el-card class="box-card" v-show="noticeShow">
      <div slot="header" class="clearfix">
        <span>消息通知</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="noticeShow = false">关闭</el-button>
      </div>
      <div class="noticeContent">
        <p v-for="(item, index) in noticeList" :key="index" class="text item">
          {{ item.message }}
          <el-button style="float: right; padding: 3px 0" type="text" @click="deleteNotice(item)">已读</el-button>
        </p>
      </div>
      <div class="footer">
        <el-button style="float: right; padding: 10px 0" type="text" @click="deleteAll">全部已读</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getAppealContent, deleteNotice, deleteAllNotice } from '@/api/user'
import { settings } from 'nprogress'
import { openSocket } from '@/utils/socket'
export default {
  name: 'top-title',
  data() {
    return {
      noticeList: [],
      noticeShow: false,
      noticeNum: 0,
    }
  },
  created() {
    openSocket('/websocket'),
      this.getAppealContent()

  },
  methods: {
    // 消息框
    showNotice() {
      this.getAppealContent()
      this.noticeShow = true
    },
    async getAppealContent() {
      const userId = +this.$store.getters.userId
      const res = await getAppealContent(userId)
      this.noticeList = res
      this.noticeNum = res.length
    },
    async deleteNotice(item) {
      const res = await deleteNotice(item)
      // console.log('delete',res);
      this.getAppealContent()
    },
    async deleteAll() {
      await deleteAllNotice()
      this.getAppealContent()
    },
    toggleMenu() {
      this.$store.commit('app/TOGGLEMENU')
    }
  }
}
</script>

<style lang="scss" scoped>
.title-content {
  position: relative;

  .tc {
    position: relative;

    margin-bottom: 20px;
    text-align: center;
    color: #fff;
    height: 54px;
    line-height: 54px;
    letter-spacing: 15px;
  }

  .layout-title {
    position:sticky;
    top: 0px;
    background-color: rgb(94, 144, 215);
    margin-bottom: 20px;
    text-align: center;
    color: #fff;
    height: 54px;
    line-height: 54px;
    letter-spacing: 15px;

    .menu {
      float: left;
      margin-top: 14px;
      font-size: 30px;
    }

    .noticeIcon {
      position: absolute;
      top: -10px;
      right: 50px;
      font-size: 30px;
      margin-top: 15px;
      // margin-right: 40px;
      letter-spacing: 0;

      ::v-deep .el-badge__content.is-fixed {
        top: 10px;
        right: 10px;
      }
    }
  }

  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }

  .clearfix:after {
    clear: both;
  }

  .noticeContent {
    height: 300px;
    overflow-y: auto;
  }

  .box-card {
    position: absolute;
    top: 55px;
    right: 25px;
    width: 480px;
    z-index: 999;
  }

  .title {
    height: 60px;
    line-height: 60px;
    padding-left: 50px;
    margin-bottom: 20px;
    background-color: #fff;

    .title-contain {
      // margin-top: 15px;
      color: rgb(72, 127, 194);
    }
  }
}
</style>