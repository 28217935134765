import Layout from '@/layout'
// 树状结构
export const tranListToTreeData = (arr, pid) => {
  const parent = arr.filter(item => item.parentId === pid)
  function findDeep(parent) {
    parent.forEach(p => {
      p.children = arr.filter(c => c.parentId === p.id)
      findDeep(p.children)
    })
  }
  findDeep(parent)
  return parent
}
const CryptoJS = require('crypto-js')
const key = CryptoJS.enc.Utf8.parse('www.shawn222.com')
// aes加密方法
export function encryptData(data) {
  const srcs = CryptoJS.enc.Utf8.parse(JSON.stringify(data)) // 替换为实际的密钥
  const encrypted = CryptoJS.AES.encrypt(srcs, key,{
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  }).toString()
  return encrypted
}

// 解密方法
export function decryptData(encryptedData) {
  if(encryptedData==null) return null
  const bytes = CryptoJS.AES.decrypt(encryptedData, key,{
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  return decryptedData
}

// export const getRoutesList = (data,arr) => {
//   data.forEach((datas, index) => {
//     arr.push({
//       path: datas.path,
//       name: datas.name,
//       component: datas.component === 'Layout' ? Layout : () => import([`@/views/${datas.component}.vue`]),
//       meta: {
//         title: datas.title,
//         icon: datas.icon,
//       },
//       //  redirect: datas.redirect,
//       // 子路由
//       children: []
//     })
//     if (datas.children) {
//       const childArr = getRoutesList(datas.children, [])
//       arr[index].children = childArr
//     }
//   })
//   return arr
// }