import request from '../utils/request'

export const login = data => {
 return request({
  method: 'post',
  url: '/user/login',
  data
 })
} 

export const logout = () => {
  return request({
    method: 'post',
    url: '/user/logout'
  })
}

export const auth = (data, phone) => {
    return request({
      method: "get",
      url: `/wx/wxcallback?code=${data}&phone=${phone}`,
    });
  };
