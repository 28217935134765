<template>
  <div class="layout-contianer">
    <div class="leftdiv">
      <LeftNav class="menu"></LeftNav>
    </div>
    <div class="right">
      <div class="title" v-if="$router.currentRoute.fullPath !== '/home'">
        <topTitle />
      </div>
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import LeftNav from "@/layout/components/LeftNav.vue";
import topTitle from "@/layout/components/top-title.vue";
import { openSocket } from "@/utils/socket";
export default {
  components: {
    LeftNav,
    topTitle,
  },
  created() {
    const userId = this.$store.getters.userId;
    openSocket(`/chat/${userId}`);
  },
};
</script>
<style lang="scss" scoped>
.layout-contianer {
  display: flex;
  height: 100vh;
  // justify-content: space-between;
  .right {
    width: calc(100% - 200px);
    // flex: 1;
    // height: 0;
    .title {
      // position: fixed;
      // top: 0;
      // right: 0;
      // left: 0;
      z-index: 9;
      // width: 100%;
      flex: 1;
    }
  }
}
</style>
