<template>
  <div id="app">
    <!-- create new branch  -->
    <router-view />
  </div>
</template>
<script>
import store from "@/store";
export default {
  name: "App",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  created() {},
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.el-table__body-wrapper::-webkit-scrollbar {
  width: 8px !important; /*滚动条宽度*/
  height: 8px !important; /*滚动条高度*/
}
.el-table__body-wrapper::-webkit-scrollbar-track {
  border-radius: 10px !important; /*滚动条的背景区域的圆角*/
  -webkit-box-shadow: inset 0 0 6px rgba(238, 238, 238, 0.3) !important;
  background-color: #eeeeee !important; /*滚动条的背景颜色*/
}
.el-table__body-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px !important; /*滚动条的圆角*/
  -webkit-box-shadow: inset 0 0 6px rgba(145, 143, 0143, 0.3) !important;
  background-color: rgb(145, 143, 143) !important; /*滚动条的背景颜色*/
}
</style>
