import store from '@/store'
import request from "./request"
import { Notification } from 'element-ui'
import { online } from '@/api/chat'
import dayjs from 'dayjs'
// 引入中文包
import 'dayjs/locale/zh-cn'
let socket = null
let that = this
let lockReconnect = false;  //避免ws重复连接
export const openSocket = (url) => {
    if (typeof (WebSocket) == "undefined") {
        console.log("您的浏览器不支持WebSocket")

    } else {
        console.log("您的浏览器支持WebSocket")
        //实现化WebSocket对象，指定要连接的服务器地址与端口  建立连接
        //等同于socket = new WebSocket("ws://localhost:9010/javatest/ws/25");
        let socketUrl = request.defaults.baseURL + url
        socketUrl = socketUrl.replace("http", "ws").replace("https", "wss")
        // console.log(socketUrl)
        socket = new WebSocket(socketUrl)
        //打开事件
        socket.onopen = function () {
            // heartCheck.reset().start();      //心跳检测重置
            console.log("websocket已打开")
        }
        //获得消息事件
        socket.onmessage = function (msg) {
            // heartCheck.reset().start();  
            // 转换json对象
            const data = JSON.parse(msg.data)
            const userId = store.getters.userId
            // 顶部消息框
            if (!data.isChat && data !== '') {
                if (data.userIds.indexOf(+userId) > -1) {
                    // console.log(data.msg)
                    Notification({
                        title: '消息',
                        message: data.msg
                    })
                }
                // 聊天室
            } else if (data.isChat && data !== '') {
                // alert(data)
                // console.log('dd',data)
                Notification({
                    title: `${data.username}     ${dayjs(data.createtime).format('HH:mm:ss')}`,
                    message: `${data.message}`
                })
            }
            // location.reload()
            //发现消息进入    开始处理前端触发逻辑
        }

        //关闭事件
        socket.onclose = function () {
            // reconnect(url)
            console.log("websocket已关闭")
        }
        //发生了错误事件
        socket.onerror = function () {
            // reconnect(url)
            console.log("websocket发生了错误")
        }

    }
}
// 发送消息
// sendMsg(value) {
//     const param = JSON.stringify(value)
//     return new Promise((resolve, reject) => {
//         this.socketExamples.send({
//             data: param,
//             success() {
//                 console.log('消息发送成功')
//                 resolve(true)
//             },
//             fail(error) {
//                 console.log('消息发送失败')
//                 reject(error)
//             }
//         })
//     })
// }

// 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
window.onbeforeunload = function () {
    socket.close()
}

function reconnect(url) {
    if (lockReconnect) return
    lockReconnect = true
    setTimeout(function () {     //没连接上会一直重连，设置延迟避免请求过多
        openSocket(url)
        lockReconnect = false
    }, 2000)
}

//心跳检测
var heartCheck = {
    timeout: 1000,        //1分钟发一次心跳
    timeoutObj: null,
    serverTimeoutObj: null,
    reset: function () {
        clearTimeout(this.timeoutObj)
        clearTimeout(this.serverTimeoutObj)
        return this
    },
    start: function () {
        var self = this
        this.timeoutObj = setTimeout(function () {
            //这里发送一个心跳，后端收到后，返回一个心跳消息，
            //onmessage拿到返回的心跳就说明连接正常
            socket.send("ping")
            console.log("ping!")
            self.serverTimeoutObj = setTimeout(function () {//如果超过一定时间还没重置，说明后端主动断开了
                socket.close();     //如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
            }, self.timeout)
        }, this.timeout)
    }
}