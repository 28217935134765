function transObjToTree(originobj) {
    var parents = originobj.filter((item) => {
      return item.affiliatedUnit == "" || item.affiliatedUnit == null;
    });
    var childrens = originobj.filter((item) => {
      return item.affiliatedUnit != "" && item.affiliatedUnit != null;
    });
    var transform = (parents, childrens) => {
      parents.forEach((parent) => {
        childrens.forEach((child, index) => {
          if (child.affiliatedUnit == parent.id) {
            var temp = JSON.parse(JSON.stringify(childrens));
            temp.splice(index, 1);
            transform([child], temp);
            typeof parent.children !== "undefined"
              ? parent.children.push(child)
              : (parent.children = [child]);
          }
        });
      });
    };
    transform(parents, childrens);
    return parents;
  }
  const arryData = (v) => {
    return v.map(item => {
      if (item.children?.length) {
        return {
          value: item.id,
          label: item.unitName,
          children: arryData(item.children)
        }
      }
      return {
        value: item.id,
        label: item.unitName,
      }
    })
  }

export default {
    transObjToTree,
    arryData
};