import { getToken, setToken, removeToken,setTimeStamp } from '@/utils/auth'
import { login } from '@/api/login'
import { getUserInfo } from '@/api/user'
import { resetRouter } from '@/router'
const state = {
  token: getToken(),  //设置token 为共享状态
  userInfo: {},
  homesocket:{}
}
// 如果vuex中的token 发生变化
const mutations = {
  setToken(state, token) {
    state.token = token; //将数据设置给vuex
    setToken(token); //同步给缓存
  },
  removeToken(state) {
    state.token = null  //将vuex的数据置空
    removeToken()
  },
  setUserInfo(state, result) {
    state.userInfo = result
  },
  removeUserInfo(state) {
    state.userInfo = {}
  }
}
// 定义
const actions = {
  // 登录
  async login(context, data) {
    const res = await login(data)
    // console.log(res);
    context.commit('setToken', res.token)
    // 记录下登录的时间戳
    setTimeStamp()
  },
  // 登出
  async logout(context, data) {
    // 删除token
    context.commit('removeToken')
     // 删除用户资料
    context.commit('removeUserInfo')
    resetRouter()
    // {root:true} 调用根级的mutation
    context.commit('permission/setRoutes', [], {root:true})
   
  },
  async getUserInfo(context) {
    const result = await getUserInfo()
    console.log(result,'人员信息')
    context.commit('setUserInfo', result.lluser)
    return result
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
