import request from '../utils/request'

// 获取聊天室联系人
export const contactor = (data) => {
  // ?type=2
  // data:{
  //   AESDTO: cloneData
  //  }
  return request({
   method: 'post',
   url: `/llUser/selectAllUsers`,
   data
  })
 } 
// export const contactor = data => {
//  return request({
//   method: 'get',
//   url: `/llUser/selectAllUsers?type=2&&userid=${data}`,
//  })
// } 

// 获取上线人信息
export const online = data => {
  return request({
    method: 'get',
    url:`/llUser/selectById?userId=${data}`
  })
}

// 获取过去的聊天记录
export const pastChat = data => {
  return request({
    method: 'post',
    url:`/message/getOldMessage?sendId=${data.sendId}&&receptionId=${data.receptionId}`
  })
}

// 获取今天的聊天记录
export const todayChat = data => {
  return request({
    method: 'post',
    url:`/message/getNowMessage?sendId=${data.sendId}&&receptionId=${data.receptionId}&&dos=${data.dos}`
  })
}

// 获取聊过天的用户
export const chatedUser = data => {
  return request({
    method: 'post',
    url:`/message/getUser`,
    data
  })
}

// 修改用户备注
export const addRemarkAPI = data => {
  return request({
    method: 'get',
    url:`/llUser/updateRemark?userId=${data.userId}&&remark=${data.remark}`, 
  })
}

// 搜索用户
export const addfinduserAPI = data => {
  return request({
    method: 'get',
    url:`/llUser/findUser?name=${data}`,
  })
}
// 修改备注
// /updateRemark"
export const updateRemarkAPI = data => {
  return request({
    method: 'get',
    url:`/message/updateRemark?userId=${data.userId}&&remark=${data.remark}`,
  })
}
