<template>
  <div class="sidebar-item">
    <!-- 二级菜单 -->
    <el-submenu v-if="(item.meta.orderNum === 0) && !item.hidden" :index="item.path">
      <template slot="title">
        <i :class="item.meta.icon"></i>
        <span>{{ item.meta.title }}</span>
      </template>
      <menu-item
        v-for="child in item.children"
        :key="compilePath(item.path,child.path)"
        :child="child"
        :basePath="item.path"
      ></menu-item>
    </el-submenu>
    <!-- 一级菜单 -->
    <menu-item
      v-else-if="item.meta.orderNum === 1 && !item.hidden"
      :child="item.children[0]"
      :basePath="item.path"
    ></menu-item>
  </div>
</template>

<script>
import MenuItem from "./item.vue";
import path from "path";
export default {
  name: "sidebar-item",
  components: {
    MenuItem,
  },
  props: {
    item: {
      type: Object,
    },
  },
  methods: {
    compilePath(itempath, childpath) {
      // return itempath+childpath
      return path.resolve(itempath, childpath);
    },
  },
  mounted(){
    // console.log('item',this.item);
  }
}
</script>
<style lang="scss">
/*隐藏文字*/
.el-menu--collapse .el-submenu__title span {
  display: none;
}
/*隐藏 > */
.el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}
</style>
