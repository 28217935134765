<template>
  <div class="menu-item">
    <el-menu-item
      :index="compilePath(child.path)"
      v-if="!child.hidden && (child.children == undefined|| child.children ==null || child.children.length == 0)"
      @click="clickEvent(child)"
    >
      <i :class="child.meta.icon"></i>
      <span slot="title">{{child.meta.title}}</span>
    </el-menu-item>
    <el-submenu :index="compilePath(child.path)" v-else>
      <!-- <i :class="child.meta.icon"></i> -->
      <span slot="title">{{child.meta.title}}</span>
      <el-menu-item
        v-for="(childs,index) in child.children"
        :index="compilePath(childs.path)"
        :key="index"
      >
        <i :class="childs.meta.icon"></i>
        <span slot="title">{{childs.meta.title}}</span>
      </el-menu-item>
    </el-submenu>
  </div>
</template>

<script>
import path from 'path'
export default {
  name:'menu-item',
  props:{
    child:{
      type:Object
    },
    basePath:{
     type:String
    }
  },
  methods:{
     compilePath(childpath) {
        return path.resolve(this.basePath, childpath)
    },
     clickEvent(child) {
      const path = this.compilePath(child.path)
      this.$router.push(path)
    },
    clickEvent1(child) {
      console.log('click1child',child)
      const path = this.compilePath(child.path)
      console.log('click1path',path)
      // this.$router.push(path)
    }
  }

}
</script>

<style lang="scss">



</style>