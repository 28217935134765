import Layout from '@/layout'
export default {
  path: '/personal',
  name:'myinfo',
  component: Layout,
  meta: {
    orderNum: 1
  },
  children: [{
    path: '',
    component: () => import('@/views/MyInfo'),
    meta: {
      icon: 'el-icon-s-custom',
      title:'个人中心',
      orderNum: 0
    }
  }]
}

